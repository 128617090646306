@tailwind base;
@tailwind components;
@tailwind utilities;

/* ANTD is conflicting with Tailwind */
button.ant-btn-primary {
  background-color: #1677ff;
}
button.ant-btn-default {
  background-color: #FFFFFF;
}

button.ant-switch {
  background: rgba(0, 0, 0, 0.25);
}
